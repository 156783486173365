<!-- 商品列表页 -->
<template>
    <div>
        <goodListTemp :name="name" :categoryId="categoryId" :productType="productType" 
            :control="control" :medicine="medicine" :designatedArea="designatedArea"/>
    </div>
</template>

<script>
import goodListTemp from '@/components/goodListTemp'
export default {
    name: 'GoodList',
    components: { goodListTemp },

    data() {
        return {
            name: this.$route.query.name ? decodeURIComponent(this.$route.query.name) : '',
            categoryId: this.$route.query.categoryId || '',
            productType: this.$route.query.productType || '',
            control: this.$route.query.control || '',
            medicine: this.$route.query.medicine || '',
            designatedArea: this.$route.query.designatedArea || '',
        };
    },

    created() {
        
    },

    methods: {

    }
}
</script>
<style lang='scss' scoped>

</style>